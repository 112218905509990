import { createTodo, loadTodos } from './todos';
import { setFilters } from './filters';
import { renderTodos } from './views';


// Render initial todos
renderTodos(); // call renderTodos to show default data: may add filters


// search text filter
document.querySelector('#search-text').addEventListener('input', (e) => {
    setFilters({
       searchText: e.target.value
    })
    renderTodos(); // ensure renderTodos is called and clears existing filters
})

// form submission handler
document.querySelector('#todo-form').addEventListener('submit', (e) => {
    const text = e.target.elements.addTodo.value.trim();
    e.preventDefault();

    if (text.length > 0) {
        createTodo(text); // create a new note function
        renderTodos(); // call renderTodos to show updated list
        e.target.elements.addTodo.value = ''; // clears input ready for next entry
    }
});


// checkbox handler
document.querySelector('#hide-completed').addEventListener('change', (e) => {
    setFilters({
        hideCompleted: e.target.checked
     })
    renderTodos();
});

// sync content of same url opened on other tab(s)
window.addEventListener('storage', (e) => {
  
    if (e.key === 'todos') {  
        loadTodos(); // load data from localstorage before render
        renderTodos();            
    }
});