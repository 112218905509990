import { v4 as uuidv4 } from 'uuid';


// initialise todos array - empty to start
let todos = [];

// loadTodos - from localStorage and parse values to todos
// assign rtn values to todos, ensures it syncs data in multi opened tabs
const loadTodos = () => {
    const todosJSON = localStorage.getItem('todos'); // rtn null if no data, else the data

    try {
        todos = todosJSON ? JSON.parse(todosJSON) : [];
    } catch(e) {
        todos = []; // if problem with data, return empty array to enable user to continue
    } 
};

// Save todos to localStorage
const saveTodos = () => {
    localStorage.setItem('todos', JSON.stringify(todos));
}

// getTodos - expose todos from module
const getTodos = () => todos;

// creates new todo to add to list
const createTodo = (text) => {
    if (text.length > 0) {
        todos.push({
            id: uuidv4(),
            text,
            completed: false
        });        
        saveTodos();
    }
}


// removes a todo from the list
const removeTodo = (id) => {
    const todoIndex = todos.findIndex((todo) => todo.id === id)

    // check if there is a match, then remove it
    if (todoIndex > -1) {
        todos.splice(todoIndex, 1);
        saveTodos();
    }
};


// toggle a completed (true/false) value for a given todo
const toggleTodo = (id) => {
    const todo = todos.find((todo) => todo.id === id)

    if (todo) {
        todo.completed = !todo.completed; // take current value, flip it & store as new value
        saveTodos();
    }    
};


// calls loadTodos to return values (data) from localStorage
loadTodos(); // set todos to return value of loadTodos()

// todo exports
export { loadTodos, getTodos, removeTodo, toggleTodo, createTodo }