import { getTodos, removeTodo, toggleTodo  } from './todos';
import { getFilters } from './filters';


// Render application todos based on filter
// renderTodos makes it flexible to diff filters (and todos)
// filters return matched text and/or checkbox 
const renderTodos = () => {
    const todos = getTodos();
    const { searchText, hideCompleted } = getFilters(); // destructure filters object
    const todosEL = document.querySelector('#todos');

    const filteredTodos = todos.filter((todo) => {
        if (todo.completed && hideCompleted) {
            return !todo.completed
        } else {
            return todo.text.toLowerCase().includes(searchText.toLowerCase());
        }       
    })
   
    // used below to check incompleted todos
    const incompletedTodos = filteredTodos.filter((todo) => !todo.completed);

    // clear prev filter - clears list and replace with new selection
    todosEL.innerHTML = '';
     
    // display summary info
    const summary = generateSummaryDOM(incompletedTodos);
    todosEL.appendChild(summary);

    // display the matched filtered todos
   if (filteredTodos.length > 0) {
        filteredTodos.forEach((todo) => {
            const todoEl = generateTodoDOM(todo)
            todosEL.appendChild(todoEl);
        });
   } else {
       const msgEl = document.createElement('p');
       msgEl.classList.add('empty-message');
       msgEl.textContent = 'No to-dos to show';
       todosEL.appendChild(msgEl);       
   }
};


// create the DOM elements for an individual Todos
const generateTodoDOM = (todo) => {
    const todoEl = document.createElement('label'); // to allow a whole row of list to be clickable
    const containerEl = document.createElement('div') // to contain list content
    const checkbox = document.createElement('input');
    const textEl = document.createElement('span');
    const removeButton = document.createElement('button');

    // set todo checkbox element
    checkbox.setAttribute('type', 'checkbox');
    checkbox.checked = todo.completed; // tick completed checkboxes. NB. forEach already in use above   
    containerEl.appendChild(checkbox);
    checkbox.addEventListener('change', (e) => {
        toggleTodo(todo.id);
        renderTodos();
    })
        
    // set todo list title text
    textEl.textContent = todo.text;
    containerEl.appendChild(textEl);

    // set up container
    todoEl.classList.add('list-item');
    containerEl.classList.add('list-item__container');
    todoEl.appendChild(containerEl);

    // set todo remove button
    removeButton.textContent = 'remove';
    removeButton.classList.add('button', 'button--text')
    todoEl.appendChild(removeButton);
    removeButton.addEventListener('click', (e) => {
        removeTodo(todo.id);
        renderTodos();
    })

    return todoEl;
};


// creat the DOM elements for list summary
const generateSummaryDOM = (incompletedTodos) => {
    const summary = document.createElement('h2');
    const todoText = incompletedTodos.length <= 1 ? 'todo' : 'todos';
    summary.classList.add('list-title');
    
    summary.textContent = `You have ${incompletedTodos.length} ${todoText} left to complete.`;
    return summary;
};


// Make sure to set up the exports
export { renderTodos, generateTodoDOM, generateSummaryDOM }