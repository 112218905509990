// filters default object
const filters = {
    searchText: '',
    hideCompleted: false
}

// expose filters via export
const getFilters = () => filters;

// set up filters with updates object to enable update via object
const setFilters = ({ searchText, hideCompleted }) => {
    if (typeof (searchText) === 'string') { 
        filters.searchText = searchText // destructured updates object
    }
   
    if (typeof (hideCompleted) === 'boolean') {
        filters.hideCompleted = hideCompleted // destructured updates object
    }   
}

export { getFilters, setFilters };